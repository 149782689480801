import { useCallback, useEffect } from 'react'
import { Card, Table, PageHeader, Tag, Tooltip } from 'antd'
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined
} from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment'
import { fm } from '../../lang'
import { usePush } from '../../data/actions/routes'
import { useBreadcrumb } from '../../helpers/hooks/useBreadcrumb'
import { changeOrdersFilters, selectOrder } from '../../data/actions/orders'
import { useLocation } from 'react-router-dom'
import { OrdersTableHeader } from './OrdersTableHeader'

export const DELIVERY_STATUSES = {
  jas_notified: 'JAS notified',
  jas_received: 'JAS received',
  jas_packed: 'JAS packed',
  jas_shipped: 'JAS shipped'
}

export const selectStatus = (status, record) => {
  const warning = record.paymentPriceCents !== record.totalCents
  switch (status) {
    case 'succeeded':
    case 'COMPLETED':
    case 'CAPTURED':
      return (
        <Tag
          color={warning ? 'warning' : 'green'}
          icon={warning ? <ExclamationCircleOutlined /> : <CheckCircleOutlined />}
        >
          SUCCESS - {record.paymentPrice}
        </Tag>
      )
    case 'requires_payment_method':
    case 'PENDING':
      return (
        <Tag
          color={warning ? 'warning' : 'cyan'}
          icon={warning ? <ExclamationCircleOutlined /> : <ClockCircleOutlined />}
        >
          PENDING - {record.paymentPrice}
        </Tag>
      )
    default:
      return (
        <Tag
          color={warning ? 'warning' : 'red'}
          icon={
            <Tooltip title={status} color="red">
              <CloseCircleOutlined />
            </Tooltip>
          }
        >
          ERROR - {record.paymentPrice}
        </Tag>
      )
  }
}

export const OrdersTable = ({ path, isLoading }) => {
  const breadcrumb = useBreadcrumb(path)
  const push = usePush()
  const dispatch = useDispatch()
  const { entries, totalPayed } = useSelector((state) => state.orders)
  const filters = useSelector((state) => state.orders.filtersEntries)
  const { pagination, sort } = filters
  const { totalCount, pageSize, current } = pagination
  const location = useLocation()
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const page = params.get('page')
    if (page && current !== page) {
      changeOrdersFilters(dispatch, [
        {
          valueType: 'pagination',
          value: {
            ...pagination,
            current: parseInt(page),
            pageSize
          }
        }
      ])
    }
  }, [])

  const columns = [
    {
      title: fm('table.number'),
      key: 'number',
      dataIndex: 'number',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: sort.by === 'number' && sort.order,
      sorter: true
    },
    {
      title: 'Date',
      key: 'createdAt',
      dataIndex: 'createdAt',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: sort.by === 'createdAt' && sort.order,
      sorter: true,
      render: (createdAt) => {
        return moment(createdAt).format('MMMM Do YYYY HH:mm')
      }
    },
    {
      title: 'Payment method',
      key: 'paymentMethod',
      dataIndex: 'paymentMethod'
    },
    {
      title: 'Total',
      key: 'total',
      dataIndex: 'total'
    },
    {
      title: 'Payment status',
      key: 'paymentStatus',
      dataIndex: 'paymentStatus',
      render: (paymentStatus, record) => {
        return selectStatus(paymentStatus, record)
      }
    },
    {
      title: 'Delivery status',
      key: 'lastDeliveryStatus',
      dataIndex: 'lastDeliveryStatus',
      render: (lastDeliveryStatus) => {
        return DELIVERY_STATUSES[lastDeliveryStatus?.identifier] || '—'
      }
    },
    {
      title: 'Customer',
      key: 'customer',
      dataIndex: 'customer',
      render: (customer) => {
        if (customer) {
          return (
            <span>
              {customer.fullName} - {customer.email}
            </span>
          )
        } else {
          return '—'
        }
      }
    }
  ]
  useEffect(() => {
    push(`orders?page=${current}`)
  }, [current])
  const onChange = (pag, filters, sorters) => {
    const { current, pageSize } = pag
    changeOrdersFilters(dispatch, [
      {
        valueType: 'pagination',
        value: {
          ...pagination,
          current,
          pageSize
        }
      },
      {
        valueType: 'sort',
        value: {
          by: sorters.columnKey,
          order: sorters.order
        }
      }
    ])
  }
  const handleClickRow = useCallback(
    (recordId) => {
      selectOrder(dispatch, recordId)
      push(`/orders/${recordId}`)
    },
    [push, dispatch, selectOrder]
  )
  return (
    <PageHeader breadcrumb={breadcrumb} title={fm('orders.title')}>
      <Card size="small">
        <OrdersTableHeader />
      </Card>
      <div style={{ marginBottom: 16 }} />
      <Card size="small">
        <Table
          onRow={(record) => {
            return {
              onClick: () => handleClickRow(record.id)
            }
          }}
          onChange={onChange}
          dataSource={entries}
          columns={columns}
          size="small"
          loading={isLoading}
          rowKey="id"
          pagination={{
            total: totalCount,
            pageSize,
            current
          }}
          summary={() => (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={4}>
                  Total: {totalCount}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} colSpan={2}>
                  {totalPayed.map(({ shopId, total }) => (
                    <div key={shopId}>{total}</div>
                  ))}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </Card>
    </PageHeader>
  )
}
